import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {
  public subMenu = false;
  subMenuTwo: boolean;
  currentYear: number;
  constructor(public dialog: MatDialog) {
    this.currentYear = (new Date()).getFullYear();
  }

  ngOnInit(): void {
    // this.dialog.open(LoginDialog);

    // When Require this section please on
    // const dialogRef = this.dialog.open(LoginDialogComponent, {
    //   width: '670px',
    //   // height: '690px',
    //   maxHeight: '80vh'
    // });
  }

  openSubmenu(): void {
    this.subMenu = !this.subMenu;
  }

  openSubmenuTwo() {
    this.subMenuTwo = !this.subMenuTwo;
  }

  navOpen() {
    const LeftBlock = document.querySelector('.mainBlock .leftBlock');
    LeftBlock?.classList.toggle('active');
  }

}


// @Component({
//   selector: 'app-login-dialog',
//   templateUrl: './login.html',
// })
// export class LoginDialogComponent {
//   constructor(
//     public dialogRef: MatDialogRef<LoginDialogComponent>, public dialog: MatDialog) { }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }
//   onClickOpenRegister(): void {
//     this.dialogRef.close();
//     const dialogRef = this.dialog.open(RegisterDialogComponent, {
//       width: '1110px',
//       // height: '670px',
//       maxHeight: '80vh'
//     });
//   }
// }

// @Component({
//   selector: 'app-register-dialog',
//   templateUrl: './register.html',
// })
// export class RegisterDialogComponent {
//   constructor(public dialogRef: MatDialogRef<RegisterDialogComponent>, public dialog: MatDialog) { }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }


// }
