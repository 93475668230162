import { Component, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { StorageService } from './services/storage.service';
import { Router } from '@angular/router';
import { EventService } from './services/event.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'default';

  constructor(
    private api: ApiService, private storage: StorageService, private router: Router, private event: EventService
  ) {
    this.event.isLogout.subscribe(res => {
      res && (this.logout())
    })
  }

  ngOnInit(): void {
    // this.getProfile() 
  }

  getProfile() {
    this.api.post(`/vendorportal/user/myprofile`).subscribe({
      next: (response: any) => {
        // console.log("profile ==> ", response);

        if (response.status === 401) {
          this.logout();
        }

        // if (!response.isApprove) {
        //   this.logout();
        // }
        // if (response.isDeleted) {
        //   this.logout();
        // }

      },

    })

  }

  logout() {
    this.storage.clearUser();
    this.router.navigateByUrl('/vendor-home');
    this.event.setLoginEmmit(false);
    this.event.logout.next(false);
  }

}
