import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MaterialModule } from '@project/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { ForgotDialogComponent } from './forgot-dialog/forgot-dialog.component';
import { ForgotDialogStep2Component } from './forgot-dialog-step2/forgot-dialog-step2.component';
import { EmailvarifyComponent } from './emailvarify/emailvarify.component';

// import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule } from '@project/material';

const modules = [
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule
];


@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotDialogComponent, ForgotDialogStep2Component, EmailvarifyComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    NgxMatIntlTelInputModule,
    ...modules
  ],
  entryComponents:[ForgotDialogComponent]
})
export class AuthModule { }
