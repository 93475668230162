import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-emailvarify',
  templateUrl: './emailvarify.component.html',
  styleUrls: ['./emailvarify.component.scss']
})
export class EmailvarifyComponent implements OnInit {

  urlToken: any;
  ID: any;
  varifyFlag:boolean=true;
  constructor(private route: ActivatedRoute,private apiService:ApiService,private storageService:StorageService,private router: Router) { }

  ngOnInit(): void {
    this.route.snapshot.params['id'] && (this.ID = this.route.snapshot.params['id']);
    console.log(this.ID);
    this.emailvarify();
    // this.setForgotUserToken();
  }
  emailvarify(){
    let data ={
      token: this.ID
    }
    this.apiService.post(`/vendorportal/user/email/verify`, data).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.apiService.alert(resp.message, 'success', 10000);
        this.varifyFlag = true;
      } else {
        this.apiService.alert(resp.message, 'warning');
        return;
      }
    }, err => {

      this.apiService.alert(err?.message, 'error')
    });
  }


  

}
