<div class="headerVendor">
  <h1>Order Details</h1>
  <app-vendor-header></app-vendor-header>
</div>

<div class="addProduct">
  <div class="orderDetailTable">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Transaction Id</th>
            <th>Order Date</th>
            <!-- <th>Currency</th> -->
            <th>Currency</th>
            <th>Total Amount</th>
            <th>Discount</th>
            <th>Amount Paid</th>
            <th>Body Measurements Csv</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{detailsData?.orderNumber}}</td>
            <td>{{detailsData?.transactionId}}</td>
            <td>{{detailsData?.orderDate | date}}</td>
            <!-- <td>{{detailsData?.currency}}</td> -->
            <td>USD</td>
            <td>{{detailsData?.totalAmount}}</td>
            <td>{{detailsData?.discount ? detailsData?.discount : 0}}</td>
            <td>{{detailsData?.amountPaid}}</td>
            <td>
              <ng-container *ngIf="detailsData?.mirrorsize_csv">
              <a (click)="download(detailsData?.mirrorsize_csv,detailsData?.orderNumber+'_csv')" class="body-ms-downld">
                <mat-icon>cloud_download</mat-icon> Download</a>
              </ng-container>
              <ng-container *ngIf="!detailsData?.mirrorsize_csv">N/A</ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="addSec">
    <div class="row">
      <div class="col-lg-6">

        <div class="orderDetailTable proDetailTable">
          <h2>Order items</h2>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of detailsData?.orderedItems">
                  <td>
                    <ng-container *ngIf="item?.productImage">
                      <img src="{{baseImgUrl}}/product/{{item?.productImage}}" alt="" />
                    </ng-container>
                    <ng-container *ngIf="!item?.productImage">
                      <img src="{{baseImgUrl}}/product/{{item?.productDetails?.image}}" alt="" />
                    </ng-container>
                  </td>
                  <td>{{item?.title}}</td>
                  <td>{{item?.quantity}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <!-- <div class="orderDetailTable proDetailTable">
              <div class="table-responsive dimensionTable">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Dimension Title</th>
                      <th>Dimension Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of detailsData?.mirrorsize_data">
                      <td>{{item?.key}}</td>
                      <td>{{item?.value}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <div class="itemAdd">
              <h4>Billing Details</h4>
              <p><span>Name:</span>
                <ng-container *ngIf="detailsData?.billingDetails?.name">{{detailsData?.billingDetails?.name}}
                </ng-container>
                <ng-container *ngIf="!detailsData?.billingDetails?.name">{{detailsData?.billingDetails?.firstName}}
                  {{detailsData?.billingDetails?.lastName}}</ng-container>
              </p>
              <p><span>address:</span> {{detailsData?.billingDetails?.address}}</p>
              <p><span>contact:</span> {{detailsData?.billingDetails?.address}}</p>
              <p><span>country:</span> {{getCountryNameById(detailsData?.billingDetails?.country)}}</p>
              <p><span>email:</span> {{detailsData?.billingDetails?.email}}</p>
              <p><span>postalCode:</span> {{detailsData?.billingDetails?.postalCode}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="itemAdd">
              <h4>Shipping Details</h4>
              <p><span>Name:</span>
                <ng-container *ngIf="detailsData?.shippingDetails?.name">{{detailsData?.shippingDetails?.name}}
                </ng-container>
                <ng-container *ngIf="!detailsData?.shippingDetails?.name">{{detailsData?.shippingDetails?.firstName}}
                  {{detailsData?.shippingDetails?.lastName}}</ng-container>
              </p>
              <p><span>address:</span> {{detailsData?.shippingDetails?.address}}</p>
              <p><span>contact:</span> {{detailsData?.shippingDetails?.address}}</p>
              <p><span>country:</span> {{getCountryNameById(detailsData?.shippingDetails?.country)}}</p>
              <p><span>email:</span> {{detailsData?.shippingDetails?.email}}</p>
              <p><span>postalCode:</span> {{detailsData?.shippingDetails?.postalCode}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="addSec" *ngIf="detailsData?.garment?.length > 0">
    <div class="row">
      <div class="col-md-12">
        <div class="orderDetailTable proDetailTable">
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Left</th>
                <th>Top</th>
                <th>Transparency</th>
                <th>Repeat</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of detailsData?.garment">
                <ng-container *ngIf="item">
                  <td data-label="Image">
                    <figure class="productImg"><img height="auto" width="auto" src="{{item?.pattern?.source}}" alt=""></figure>
                  </td>
                  <td data-label="Title">{{item?.title}}</td>
                  <td data-label="Left">{{item?.pattern?.left}}</td>
                  <td data-label="Top">{{item?.pattern?.top}}</td>
                  <td data-label="Transparency">{{item?.transparency}}</td>
                  <td data-label="Repeat">{{item?.pattern?.repeat}}</td>
                </ng-container>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>


  <!-- <div class="measurement" *ngIf="detailsData?.mirrorsize_data && detailsData?.mirrorsize_data?.length > 0">
    <h2>Your Body Measurements</h2>
    <div class="measurement_body">
      <ul class="list-unstyled">
        <li *ngFor="let item of detailsData?.mirrorsize_data">
          <div>{{item?.key}} : <b>{{item?.value}}</b></div>
          <div class="measurementEyeButton">
            <mat-icon>visibility</mat-icon>
            <div class="measurementEyeImg">
              <ng-container *ngIf="detailsData?.gender == 'Male'">
                <img src="{{baseImgUrl}}/mirrorsize_male/{{item?.image}}" alt="">
              </ng-container>
              <ng-container *ngIf="detailsData?.gender != 'Male'">
                <img src="{{baseImgUrl}}/mirrorsize_female/{{item?.image}}" alt="">
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div> -->

  <div class="measurement measurmentProductWrapper" *ngIf="detailsData?.body_measurement && detailsData?.body_measurement?.length > 0">
    <div class="measurmentProduct">
      <h2>Your Body Measurements</h2>
      <ng-container *ngFor="let item of detailsData?.body_measurement">
        <div class="inch_heading">
          <h4>{{item?.title}}</h4>
          <div class="inchcmwrap">
            <ul>
              <li (click)="switchLength('inch')" [class.active]="sizeType === 'inch'">Inch</li>
              <li (click)="switchLength('cm')" [class.active]="sizeType === 'cm'">Cm</li>
            </ul>
          </div>
        </div>
      <div class="measurement_body" *ngIf="item?.measurements && item?.measurements?.length > 0">
        <ul class="list-unstyled">
          <ng-container *ngFor="let items of item?.measurements">
          <li  *ngIf="items?.value_in || items?.value_cm">
            <div >{{items?.key}} : <b *ngIf="sizeType === 'inch'">{{items?.value_in}}</b> <b
              *ngIf="sizeType === 'cm'">{{items?.value_cm}}</b></div>
            <div class="measurementEyeButton" >
              <mat-icon>visibility</mat-icon>
              <div class="measurementEyeImg">
                <ng-container *ngIf="detailsData?.gender == 'Male'">
                  <img src="{{baseImgUrl}}/mirrorsize_male/{{items?.image}}" alt="">
                </ng-container>
                <ng-container *ngIf="detailsData?.gender != 'Male'">
                  <img src="{{baseImgUrl}}/mirrorsize_female/{{items?.image}}" alt="">
                </ng-container>
              </div>
            </div>
          </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
    </div>

    <button class="btn" (click)="onChange()">Show Other Measurements</button>
    <div class="measurement_body" *ngIf="more">
      <ul class="list-unstyled">
        <li *ngFor="let item of detailsData?.mirrorsize_data">
          <div>{{item?.key}} : <b *ngIf="sizeType === 'inch'">{{item?.value_in}}</b> <b
            *ngIf="sizeType === 'cm'">{{item?.value_cm}}</b></div>
          <div class="measurementEyeButton">
            <mat-icon>visibility</mat-icon>
            <div class="measurementEyeImg">
              <ng-container *ngIf="detailsData?.gender == 'Male'">
                <img src="{{baseImgUrl}}/mirrorsize_male/{{item?.image}}" alt="">
              </ng-container>
              <ng-container *ngIf="detailsData?.gender != 'Male'">
                <img src="{{baseImgUrl}}/mirrorsize_female/{{item?.image}}" alt="">
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>