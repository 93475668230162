import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private storage: StorageService
  ) { }

  loading = new BehaviorSubject(false);
  isLoading = this.loading.asObservable();

  Login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.Login.asObservable();

  user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  logout: BehaviorSubject<any> = new BehaviorSubject(false);
  isLogout = this.logout.asObservable();



  // ALTERNATIVE
  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }
  async setLoginEmmit(isLogin: boolean) {
    return await this.Login.next(isLogin);
  }
  
  setUserUpdateEmmit(isUserUpdate: boolean) {
    return this.user.next(isUserUpdate);
  }
}
