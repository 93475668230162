import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { LoginComponent } from '../login/login.component';
import { ApiService } from '@project/services/api.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  primaryInfo: FormGroup;
  securityForm: FormGroup;
  countries: any;
  businessInfo: FormGroup;
  billingInfo: FormGroup;
  businesstypes: any;
  imageSrc: string;
  securityFm = false;
  businessInfoFm = false;
  billingInfoFm = false;
  otpField: any;
  OtpResp: any;
  documentIdName: any;
  verificationDocumentName: any;
  timeLeft: number;
  countryCode: any;
  newResFlag: boolean = false;
  isLoader: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<RegisterComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
  }

  startTimer() {
    this.timeLeft = 60 * 5;
    setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000);
  }


  ngOnInit(): void {
    this.primaryInfo = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+')]],
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]],
      mobile: [''],
      country: ['', [Validators.required]],
      otpType: ['email']
    });
    this.securityForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],
      // emailOTP: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      // mobileOTP: ['', [Validators.minLength(4), Validators.maxLength(4)]],
    });
    this.businessInfo = this.fb.group({
      vendorType: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      businessRegistrationNumber: ['', [Validators.required]],
      businessAddress: ['', [Validators.required]],
      primaryContactPerson: ['', [Validators.required, this.customEmailMobileValid]],
      verificationDocumentType: ['', [Validators.required]],
      verificationDocument: ['', [Validators.required]],
      publicStoreName: ['', [Validators.required]],
      idDocumentType: ['', [Validators.required]],
      idDocument: ['', [Validators.required]],
    });
    this.billingInfo = this.fb.group({
      cardNumber: [''],
      expMonth: [''],
      expYear: [''],
      cvv: [''],
      billAddress: [''],
    });
    this.getCountries();
    this.getBusinessTypes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickOpenLogin(): void {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '670px',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper, formName: string) {
    if (formName === 'primaryInfo') {
      if (this.primaryInfo.valid) {
        console.log(this.primaryInfo.value);
        if (this.primaryInfo.value.email && this.primaryInfo.value.mobile) {
          this.primaryInfo.patchValue({ 'otpType': 'both' });
        } else if (this.primaryInfo.value.email) {
          this.primaryInfo.patchValue({ 'otpType': 'email' });
        } else if (this.primaryInfo.value.mobile) {
          this.primaryInfo.patchValue({ 'otpType': 'mobile' });
        }

        const payLoad = {
          type: this.primaryInfo.value.otpType,
          email: this.primaryInfo.value.email,
          mobile: this.primaryInfo.value.mobile,
          name: this.primaryInfo.value.name,
          country: this.primaryInfo.value.country
        };
        this.apiService.post(`/vendorportal/user/sendotp`, payLoad).subscribe((resp: any) => {
          if (resp.status === 200) {
            this.OtpResp = resp.data;
            this.apiService.alert(resp?.message, 'success', 10000);
            stepper.next();
            this.startTimer();
          } else {
            this.apiService.alert(resp.message, 'warning');
            // return;
          }
        });
      } else {
        this.primaryInfo.markAllAsTouched();
      }
    }

    if (formName === 'securityForm') {
      const payLoad = {
        type: this.primaryInfo.value.otpType,
        emailOTP: this.securityForm.value.emailOTP,
        mobileOTP: this.securityForm.value.mobileOTP,
        userId: this.OtpResp.saveUser._id,
      };
      this.apiService.post(`/vendorportal/user/verifyotp`, payLoad).subscribe((resp: any) => {
        if (resp.status === 200) {
          this.apiService.alert(resp.message, 'success');
          stepper.next();
        } else {
          this.apiService.alert(resp.message, 'warning');
        }
      }, err => {
        this.apiService.alert(err.message, 'error');
      });
    }

  }

  gotoBusinessStep(stepper: MatStepper) {
    stepper.next();
  }

  resendOTP() {
    const payLoad = {
      type: this.primaryInfo.value.otpType,
      email: this.primaryInfo.value.email,
      // mobile: this.primaryInfo.value.mobile,
      name: this.primaryInfo.value.name,
      country: this.primaryInfo.value.country
    };
    this.apiService.post(`/vendorportal/user/sendotp`, payLoad).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.OtpResp = resp.data;
        this.apiService.alert(resp.message, 'success');
        this.startTimer();
      } else {
        this.apiService.alert(resp.message, 'warning');
        return;
      }
    });
  }

  getCountries() {
    this.apiService.get(`/vendorportal/masterdata/countries`).subscribe((res: any) => {
      this.countries = res.data;
    });
  }

  onCountryClicked() {
    this.countryCode = undefined;
  }

  onCountryChange(ev: any) {
    this.countries.map((x: any) => {
      if (x._id === ev.value) {
        this.countryCode = x.countryCode.toLowerCase();
      }
    })
  }

  getBusinessTypes() {
    this.apiService.get(`/vendorportal/masterdata/vendortypes`).subscribe((res: any) => {
      this.businesstypes = res.data;
    });
  }

  uploadverificationDocument(imageFiles: any) {
    const file: File = imageFiles.files[0];
    // console.log(file.type);
    // if (file.type.match(/image\/*/) === null || file.type.match(/application\/*/) === null) {
    //   this.apiService.alert('Only images are supported.', 'error');
    //   return;
    // } else {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   this.verificationDocumentName = imageFiles.files[0].name;
    //   reader.onload = (res: any) => {
    //     // this.profile_picPreview = res.target.result;
    //   };
    //   this.businessInfo.patchValue({ verificationDocument: file });
    // }

    if (file.type.includes('audio/') ||  file.type.includes('video/')) {
      this.apiService.alert('Images, pdf and document are supported.', 'error');

    } else{
      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.verificationDocumentName = imageFiles.files[0].name;
      reader.onload = (res: any) => {
        // this.profile_picPreview = res.target.result;
      };
      this.businessInfo.patchValue({ verificationDocument: file });
    } 
  }

  uploadIdDocument(imageFiles: any) {
    const file: File = imageFiles.files[0];
    console.log(file.type, '  ', imageFiles.files[0]);

    // if (file.type.match(/image\/*/) === null) {
    //   this.apiService.alert('Only images are supported.', 'error');
    //   return;
    // } else {
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // this.documentIdName = imageFiles.files[0].name;
    // reader.onload = (res: any) => {
    //   // this.profile_picPreview = res.target.result;
    // };
    // this.businessInfo.patchValue({ idDocument: file });
    // }

    if (file.type.includes('audio/') ||  file.type.includes('video/')) {
      this.apiService.alert('Images, pdf and document are supported.', 'error');

    } else {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.documentIdName = imageFiles.files[0].name;
      reader.onload = (res: any) => {
        // this.profile_picPreview = res.target.result;
      };
      this.businessInfo.patchValue({ idDocument: file });
    }

  }

  // submit(formName: string) {
  //   if (formName === 'businessInfo') {
  //     if (this.businessInfo.valid && this.securityForm.valid) {
  //       const formData: any = new FormData();
  //       formData.append('name', this.primaryInfo.value.name);
  //       formData.append('email', this.primaryInfo.value.email);
  //       formData.append('password', this.securityForm.value.password);
  //       formData.append('mobile', this.primaryInfo.value.mobile);
  //       formData.append('country', this.primaryInfo.value.country);
  //       let vendorType: any = this.businessInfo.value.vendorType;
  //       for (var i = 0; i < vendorType.length; i++) {
  //         formData.append(`vendorType[${i}]`, vendorType[i]);
  //       }
  //       formData.append('businessName', this.businessInfo.value.businessName);
  //       formData.append('businessRegistrationNumber', this.businessInfo.value.businessRegistrationNumber);
  //       formData.append('businessAddress', this.businessInfo.value.businessAddress);
  //       formData.append('primaryContactPerson', this.businessInfo.value.primaryContactPerson);
  //       formData.append('publicStoreName', this.businessInfo.value.publicStoreName);
  //       formData.append('verificationDocumentType', this.businessInfo.value.verificationDocumentType);
  //       formData.append('verificationDocument', this.businessInfo.value.verificationDocument);
  //       formData.append('idDocumentType', this.businessInfo.value.idDocumentType);
  //       formData.append('idDocument', this.businessInfo.value.idDocument);
  //       formData.append('userId', this.OtpResp.saveUser._id);


  //       this.apiService.postMultiDatastring(`/vendorportal/user/register`, formData).subscribe((resp: any) => {
  //         if (resp.status === 200) {
  //           this.apiService.alert('Registered successfully.', 'success');
  //           this.primaryInfo.reset();
  //           this.onClickOpenLogin();
  //         } else {
  //           this.apiService.alert(resp.message, 'error');
  //         }
  //       }, err => {
  //         this.apiService.alert(err.message, 'error');
  //       });
  //     } else {
  //       this.businessInfo.markAllAsTouched();
  //     }
  //   }
  // }


  // -------------------- NEW REGISTER -----------------------

  matchPassword(eve: any): void {
    if (eve) {
      if (
        this.securityForm.controls.password.value &&
        this.securityForm.controls.confirmPassword.value
      ) {
        if (
          this.securityForm.controls.password.value !==
          this.securityForm.controls.confirmPassword.value
        ) {
          this.securityForm.controls.confirmPassword.setErrors({
            noMatch: true,
          });
        } else {
          this.securityForm.controls.confirmPassword.clearValidators();
          this.securityForm.controls.confirmPassword.updateValueAndValidity();
        }
      }
    }
  }


  submitNew() {
    console.log(this.businessInfo);

    if (this.businessInfo.valid && this.securityForm.valid && this.primaryInfo.valid) {
      this.isLoader = true;
      const formData: any = new FormData();
      formData.append('name', this.primaryInfo.value.name);
      formData.append('email', this.primaryInfo.value.email);
      formData.append('password', this.securityForm.value.password);
      formData.append('mobile', this.primaryInfo.value.mobile);
      formData.append('country', this.primaryInfo.value.country);
      let vendorType: any = this.businessInfo.value.vendorType;
      for (var i = 0; i < vendorType.length; i++) {
        formData.append(`vendorType[${i}]`, vendorType[i]);
      }
      formData.append('businessName', this.businessInfo.value.businessName);
      formData.append('businessRegistrationNumber', this.businessInfo.value.businessRegistrationNumber);
      formData.append('businessAddress', this.businessInfo.value.businessAddress);
      formData.append('primaryContactPerson', this.businessInfo.value.primaryContactPerson);
      formData.append('publicStoreName', this.businessInfo.value.publicStoreName);
      formData.append('verificationDocumentType', this.businessInfo.value.verificationDocumentType);
      formData.append('verificationDocument', this.businessInfo.value.verificationDocument);
      formData.append('idDocumentType', this.businessInfo.value.idDocumentType);
      formData.append('idDocument', this.businessInfo.value.idDocument);
      // formData.append('userId', this.OtpResp.saveUser._id);


      this.apiService.postMultiDatastring(`/vendorportal/user/register`, formData).subscribe((resp: any) => {
        if (resp.status === 200) {
          // this.apiService.alert('Registered successfully.', 'success');
          // this.primaryInfo.reset();
          this.businessInfo.reset();
          this.securityForm.reset();
          // this.onClickOpenLogin();
          this.sendEmail();
          this.newResFlag = true;
          this.isLoader = false;
        } else {
          this.apiService.alert(resp.message, 'error');
          this.isLoader = true;
        }
      }, err => {
        this.apiService.alert(err.message, 'error');
        this.isLoader = true;
      });
    } else {
      this.businessInfo.markAllAsTouched();
      this.primaryInfo.markAllAsTouched();
      this.securityForm.markAllAsTouched();
    }
  }

  sendEmail() {
    const payLoad = {

      email: this.primaryInfo.value.email
    };
    this.apiService.post(`/vendorportal/user/verification-email/send`, payLoad).subscribe((resp: any) => {
      if (resp.status === 200) {
        // this.apiService.alert(resp.message, 'success');
        this.startTimer();
      } else {
        this.apiService.alert(resp.message, 'warning');
        return;
      }
    });
  }

  resendEmail() {
    const payLoad = {
      email: this.primaryInfo.value.email
    };
    this.apiService.post(`/vendorportal/user/verification-email/resend`, payLoad).subscribe((resp: any) => {
      if (resp.status === 200) {
        // this.OtpResp = resp.data;
        this.apiService.alert(resp.message, 'success');
        this.startTimer();
      } else {
        this.apiService.alert(resp.message, 'warning');
        // return;
      }
    });
  }






  customEmailMobileValid(control: AbstractControl): ValidationErrors | null {
    let validateEmail = (email: any) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    let validatePhone = (phone: any) => {

      const mobileNumberPattern = /^[1-9]\d*$/;
      return phone?.match(mobileNumberPattern)

    };
    let ve = validateEmail(control?.value);
    let vp = validatePhone(control?.value);

    if (ve || vp) {
      return null
    } else {
      if (ve == null) {
        return { message: "please enter valid email or phone number", ep: true }
      } else {
        return { message: "please enter valid email or phone number", ep: true }
      }
    }


  }

}
