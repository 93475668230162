import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private storage: StorageService,
    private router: Router,
    private event: EventService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  submit() {
    if (this.form.valid) {
      this.apiService.post(`/vendorportal/user/changepassword`, this.form.value).subscribe((res: any) => {
        if (res.status === 200) {
          this.apiService.alert(res.message, 'success');
         
            this.storage.clearUser();
            this.router.navigateByUrl('/vendor-home');
            this.event.setLoginEmmit(false);
          
        } else {
          this.apiService.alert(res.message, 'error');
        }
        // this.form.reset();
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

}
