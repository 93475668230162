import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { StorageService } from '@project/services/storage.service';
import { Editor } from 'ngx-editor';
import * as _ from 'underscore';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from '@envconfig';
import Swal from 'sweetalert2';
import { NgxImageCompressService } from 'ngx-image-compress';
import { fabric } from 'fabric';
import patternJson from '../../../../assets/csv/pattern.json';
import { MatDialog } from '@angular/material/dialog';
import { EditHelpComponent } from './edit-help/edit-help.component';
import { result } from 'underscore';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit, OnDestroy {

  productCategory: FormGroup;
  productInfo: FormGroup;
  productAttributes: FormGroup;
  filtersAttributes: FormGroup;
  fabricOrientation: FormGroup;
  productPricing: FormGroup;
  imageForm: FormGroup;
  addFabrics: FormGroup;
  customDimension: FormGroup;
  reviewForm: FormGroup;
  materials: FormArray;
  countries: any;
  businesstypes: any;
  productTypeList: any;
  subProdTypeList: any;
  productCategoryList: any;
  subCategoryList: any;
  fabricList: any;
  finalCombArr: any;
  finalAttributeCombData: any;
  attributes: any;
  variationImages: any;
  garmentTypes: any;
  plusPrice: any;
  twoFabric: any;
  threeFabric: any;
  fourFabric: any;
  fiveFabric: any;
  sixFabric: any;
  cyodSizePrice: any;
  plusSizePrice: any;
  plusSizeValue: any = '16/20/48';
  longDressPrice: any;
  priceCalculateOn: any;
  calfLengthFull: any;
  calfLengthThreeFour: any;
  ankleLengthFull: any;
  ankleLengthThreeFour: any;
  customDimensionImage: any;
  fabricTypeCategoryList: any;
  fabricTypeSubCategoryList: any;
  files: any = [];
  attributeValueData: any = [];
  filterValueData: any = [];
  arrayFinalResult: any = [];
  fabricCategory: any = [];
  imageForCYOD: any = [];
  imageForCYODFinal: any = [];
  cyodImages: any = [];
  customDimensions: any = [];
  selectedCustomDimensions: any = [];
  vendorFabricSelection: any = [];
  allFabricProvider: any = [];
  finalFilterValueData: any = [];
  step3VisiblePart: boolean;
  stepSixAndFiveFields: boolean;
  step3TwoFields: boolean;
  isShowVariantTable: boolean;
  stepFabricShowField: boolean;
  fabricPriceValue: number = 0;
  selectedCYOD: number = 0;
  selectedLongDress: number = 0;
  selectedPlus: number = 0;
  selectedIndex: number = 0;
  editor: Editor;
  editorF: Editor;
  imageChangedEvent: any = '';
  imageSrc: any = '';
  dimnSwitch: string = 'inputs';
  imageUrl = environment.IMAGE_BASE_URL;
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  plusSizeChart: any = ['1/4/32', '2/6/34', '4/8/36', '6/10/38', '8/12/40', '10/14/42', '12/16/44', '14/18/46', '16/20/48', '18/22/50', '20/24/52', '22/26/54', '24/28/56']
  fabricVendorList: any;
  editingStatus: boolean = false;
  isEditing: boolean = false;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  fabricTypeCategory: any;
  fabricTypeSubCategory: any;
  isDirty = false;
  allCyodData: any;
  isSave: boolean = true;
  updatedPattern: any;
  isOrientation: string = 'no';
  p: number = 1;
  lengthType: string = 'inch';
  gender:any='female'
  customDimensionsComment: any = [];
  filters: any;
  totalprice: any;
  patternData: any;
  mirror_image: any = 'image_1676629565501_Knee_ankle_length.png';
  patternMirrorsizeData: any;
  mirrorArrSeclect:any=[];
  productType: any;
  scale: any = '0';
  value = 0;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public router: Router,
    public storage: StorageService,
    private imageCompress: NgxImageCompressService,
    public dialog: MatDialog
  ) {
    this.editor = new Editor();
    this.editorF = new Editor();
    this.formsInit();
    this.step3VisiblePart = true;
  }

  ngOnInit(): void {
    this.getProductType();
    this.getSettingValue();
    this.getGarmentTypes();
    this.getFebric();
    this.getPatternType();
    // this.addMaterials();
    this.getCustomDimen();
    this.getFabricCategory();
    this.getMirrorsize(this.gender);
    setTimeout(() => { this.stepper._getIndicatorType = () => 'number'; }, 1000);
    this.productCategory.controls.productType.valueChanges.subscribe((val: any) => {
      // const data = { product_type: this.productCategory.controls.productType.value, product_sub_type: val };
      // this.getProductCategory(data);
    });
    // To Check form unsaved or not
    this.productInfo.valueChanges.subscribe(e => this.isDirty = true);
    sessionStorage.setItem("dimensionLengthType", JSON.stringify(this.lengthType));
  }

  canDeactivate() {
    return this.isDirty;
  }
  capitalizeFirstLetter(event: any) {
    const str = event.target.value
    this.productInfo.get('productName')?.patchValue(str.charAt(0).toUpperCase() + str.slice(1))

  }

  getPatternType() {
    this.apiService.get(`/vendorportal/pattern_type/list`).subscribe((res: any) => {
      this.patternData = res.data;
    });
  }

  getProductType() {
    this.apiService.get(`/vendorportal/masterdata/product-types`).subscribe((res: any) => {
      console.log(res);

      this.productTypeList = res.data;
      this.productCategory.controls.productType.patchValue(this.productTypeList[0]._id);
      let event = { value: this.productTypeList[0]._id };
      this.checkProductType(event);
    });
  }

  getSettingValue() {
    this.apiService.get(`/vendorportal/masterdata/settings`).subscribe((res: any) => {
      res.data.map((x: any) => {
        if (x.slug === "plus-size-pricing") { this.plusPrice = x }
        if (x.slug === "two-fabric-garment") { this.twoFabric = x }
        if (x.slug === "three-fabric-garment") { this.threeFabric = x }
        if (x.slug === "four-fabric-garment") { this.fourFabric = x }
        if (x.slug === "five-fabric-garment") { this.fiveFabric = x }
        if (x.slug === "six-fabric-garment") { this.sixFabric = x }
        if (x.slug === "calf-length-three-four-sleve") { this.calfLengthThreeFour = x }
        if (x.slug === "calf-length-full-sleve") { this.calfLengthFull = x }
        if (x.slug === "ankle-length-three-four-sleve") { this.ankleLengthThreeFour = x }
        if (x.slug === "ankle-length-full-sleve") { this.ankleLengthFull = x }
      })
    });
  }

  getCustomDimen() {
    this.apiService.get(`/vendorportal/masterdata/product-custom-dimension`).subscribe((res: any) => {
      this.customDimensions = res.data;
    });
  }
  getMirrorsize(gender:any) {
    let genders: any
    if (gender == 'male') {
      genders = { gender: 'Male' }
    } else {
      genders = { gender: 'Female' }
    }

    this.apiService.post(`/vendorportal/mirrorsize/list`, genders).subscribe((res: any) => {
      if (res?.status == 200) {
        this.mirror_image = res?.data[0]?.image;
        this.patternMirrorsizeData = res.data;
      } else {
        this.mirror_image = '';
        this.patternMirrorsizeData = [];
      }

    });
  }

  sizePricing(ev: any, priceCalculateOn: any) {
    this.fabricPriceValue = Number(ev.value);
    this.priceCalculateOn = priceCalculateOn;
  }

  checkChangePrice() {
    this.productPricing.controls.selectPricing.reset();
  }

  selectCyod(ev: any, fabricType: any) {
    this.selectedCYOD = (Number(fabricType.setting_value) / 100) * this.fabricPriceValue;
    this.cyodSizePrice = {
      value: ev.value,
      percentage: fabricType.setting_value + '%',
      amount: (Number(fabricType.setting_value) / 100) * this.fabricPriceValue
    }
  }

  selectLongDress(ev: any, fabricType: any) {
    this.selectedLongDress = (Number(fabricType.setting_value) / 100) * this.fabricPriceValue;
    this.longDressPrice = {
      value: ev.value,
      percentage: fabricType.setting_value + '%',
      amount: (Number(fabricType.setting_value) / 100) * this.fabricPriceValue
    }
  }

  selectPlus(ev: any, plusSize: any) {
    if (ev.checked) {
      this.selectedPlus = (Number(plusSize.setting_value) / 100) * this.fabricPriceValue;
      this.plusSizePrice = {
        value: this.plusSizeValue,
        percentage: plusSize.setting_value + '%',
        amount: (Number(plusSize.setting_value) / 100) * this.fabricPriceValue
      }
    } else {
      this.selectedPlus = 0;
      this.plusSizePrice = '';
    }
  }

  selectPlusSize(ev: any) {
    this.plusSizeValue = ev.value;
  }

  getFebric() {
    this.apiService.get(`/vendorportal/masterdata/categoriesbytype/fabric`).subscribe((res: any) => {
      this.fabricList = res.data;
    });
  }

  getFabricCategory() {
    this.apiService.post(`/vendorportal/masterdata/category-for-fabric`, {}).subscribe((res: any) => {
      this.fabricTypeCategoryList = res.data;
    });
  }

  checkFabric(event: any, index: number) {
    this.apiService.get(`/vendorportal/masterdata/categories/${event.value}`).subscribe((res: any) => {
      this.fabricCategory[index] = res.data;
    });
  }

  getAttributes(val: any) {
    this.apiService.get(`/vendorportal/masterdata/attributes/${val}`).subscribe((res: any) => {
      this.attributes = res.data;
      this.filters = res.data;
    });
  }

  removeSelectedFile(index: any) {
    this.files.splice(index, 1);
    console.log(this.files);
    
    const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   if (event.target.files[0].type !== 'image/svg+xml') {
    //     if (event.target.files[0].type.split('/')[0] === 'image') {
    //       const [file] = event.target.files;
    //       reader.readAsDataURL(file);
    //       if (type === 'profileImg') {
    //         reader.onload = () => {
    //           this.imageSrc = reader.result as string;
    //           this.form.patchValue({
    //             profileImage: event.target.files[0]
    //           });

    //         };
    //       }
    //     } else {
    //       this.apiService.alert('Invalid File Format', 'error');
    //     }
    //   } else {
    //     this.apiService.alert('Invalid File Format', 'error');
    //   }
    // }
  }

  setIndex(event: any) {
    this.selectedIndex = event.selectedIndex;
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; }
    return true;
  }

  sizeUnit(event:any){
    console.log(this.productPricing.value.fabricUnit, '  --   ',event?.value);
    
    this.productPricing.patchValue({fabricUnit: event?.value})
    // this.productPricing.patchValue({fabricUnit: type})
  }

  checkProductType(event: any) {
    // this.productTypeList.forEach((element: any) => {
    //   if (element._id === event.value) { this.subProdTypeList = element.subProductTypes; }
    // });
    console.log(event.value);
    this.productType = event.value
    this.checkProductSubType(event);
  }

  checkProductCategory(event: any) {
    console.log(event);
    
    this.productCategoryList.forEach((element: any) => {
      if (element._id === event.value) { this.subCategoryList = element.subCategories;}
    });
  }

  checkFabricCategory(event: any) {
    this.fabricTypeCategory = event.value;
    this.fabricTypeCategoryList.forEach((element: any) => {
      if (element._id === event.value) {
        this.fabricTypeSubCategoryList = element.subCategories;
      }
    });
  }
  checkFabricSubCategory(event: any) {
    this.fabricTypeSubCategory = event.value;
  }

  checkSubProductCategory(event: any) {
    this.subCategoryList.forEach((element: any) => {
      if (element._id === event.value) {
        if (element.attributeId && element.attributeId.length > 0) {
          this.getAttributes(element._id);
        } else { this.getAttributes(element.parentId); }
      }
    });
  }

  checkAttributeType(val: any) {
    let checkTitle: any;
    this.attributes.map((at: any) => { if (at._id === val) { checkTitle = at.title; } });
    return checkTitle;
  }

  checkAttributeField(val: any) {
    let checkField: any;
    this.attributes.map((at: any) => { if (at._id === val) { checkField = at.type_val; } });
    return checkField;
  }

  checkSubAttributeType(val: any, index: number) {
    let titleName: any = [];
    titleName = val.split(',')[index];
    return titleName;
  }

  onInputScale(event: any) {
    
    console.log(this.productAttributes.value.scaleValue, event);
    this.scale = event.target.value;
    this.productAttributes.patchValue({scaleValue:event.target.value})
  }

  attributeOption(event: any) {
    const dummAttr: any = [];
    event.value.map((ev: any) => {
      const addAttribute: any = [{ title: '', parentId: ev }];
      this.apiService.get(`/vendorportal/masterdata/attributevalues/${ev}`).subscribe((res: any) => {
        dummAttr.push({ id: ev, data: res.data, selectedAttr: [], selectedAttrName: [], addAttribute });
      });
    });
    this.attributeValueData = dummAttr;
    setTimeout(() => {
      this.attributes.map((x: any) => { this.attributeValueData.map((y: any) => { if (x._id === y.id) { y.isRequired = x.isRequired; } }); });
    }, 500);
    this.arrayFinalResult = [];
  }

  filterOption(event: any) {
    const filterAttr: any = [];
    event.value.map((ev: any) => {
      const addAttribute: any = [{ title: '', parentId: ev }];
      this.apiService.get(`/vendorportal/masterdata/attributevalues/${ev}`).subscribe((res: any) => {
        filterAttr.push({ id: ev, data: res.data, selectedAttr: [], selectedAttrName: [], addAttribute });
      });
    });
    this.filterValueData = filterAttr;
    setTimeout(() => {
      this.attributes.map((x: any) => { this.filterValueData.map((y: any) => { if (x._id === y.id) { y.isRequired = x.isRequired; } }); });
    }, 500);
    console.log('filterValueData', this.filterValueData);

  }

  saveField(id: any, title: any) {
    if (title) {
      const data = { attributeId: id, vendorId: this.storage.getUser().vendorId, title };
      this.apiService.post(`/vendorportal/product/attribute-value-store`, data).subscribe((res: any) => {
        if (res.status === 200) {
          this.attributeValueData.map((x: any) => {
            if (x.id === id) {
              res.data.checked = true;
              x.data.push(res.data);
              x.addAttribute[0].title = '';
              this.selectAttrVal({ checked: true, source: { value: res.data._id, id: res.data.title } }, res.data.attributeId);
              this.isShowVariantTable = true;
            }
          });
          this.apiService.alert(res.message, 'success');
        } else {
          this.apiService.alert(res.message, 'warning');
        }
      }, err => { this.apiService.alert(err.message, 'error'); });
    } else { this.apiService.alert('Field cannot be empty', 'error'); }
  }

  chunkArray(myArray: any, chunkSize: number) {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];
    let myChunk: any;
    for (index = 0; index < arrayLength; index += chunkSize) {
      myChunk = myArray.slice(index, index + chunkSize);
      tempArray.push(myChunk);
    }
    return tempArray;
  }

  checkImageSize(fileInput: any) {
    const URL = window.URL || window.webkitURL;
    const Img = new Image();
    const filesToUpload = (fileInput.target.files);
    Img.src = URL.createObjectURL(filesToUpload[0]);
    Img.onload = (e: any) => {
      const height = e.path[0].height;
      const width = e.path[0].width;
      const size = e.path[0].size;
    };
  }

  getProductCategory(data: any) {
    this.apiService.post(`/vendorportal/masterdata/category-by-producttype`, data).subscribe((res: any) => {
      console.log(res);

      this.productCategoryList = res.data;
    });
  }

  showVariantTable() {
    this.isShowVariantTable = !this.isShowVariantTable;
  }

  checkKeyEvent(ev: any, id: any, title: any) {
    if (ev.key === 'Enter') { this.saveField(id, title); }
  }

  fabricProvider(ev: any) {
    if (this.addFabrics.controls.fabricProvide.value === 'myself') {
      this.apiService.get(`/vendorportal/product/fabric-by-vendor`).subscribe((res: any) => {
        this.fabricVendorList = res.data;
        this.vendorFabricSelection = [];
        this.imageForCYOD = [];
        this.addFabric();
      });
    }
  }

  checkFabricSupplier(event: any) {
    this.apiService.post(`/vendorportal/masterdata/fabric-provider`, { subCategory: event.value }).subscribe((res: any) => {
      if (res.status === 200) {
        this.allFabricProvider = res.data;
        // this.imageForCYOD = [];
      }
    });
  }

  addFabric() {
    this.imageForCYOD.map((x: any, i: number) => {
      if (x.name === '') {
        this.apiService.alert('Name field is required', 'error');
        this.imageForCYOD.splice(i, 1);
      }
    })
    this.imageForCYOD.push({ fRimage: '', imagePreview: '', garmentTypeId: '', freezeFabric: false, color: '', materialCategory: '', materialSubCategory: '', materialPattern: '', name: '', fabricLength: '', price: '', fabricWidth: '', quantity: 1, imageOpacity: 1 });
    let objDiv = document.getElementsByClassName("addPadding")[0];
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  deleteFabric(item: any, index: number) {
    this.imageForCYOD.splice(index, 1);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageSrc = event.base64;
    this.imageForm.patchValue({
      image: this.base64ToFile(
        event.base64,
        this.imageChangedEvent.target.files[0].name,
      )
    });
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  switchDimension(val: string) {
    this.dimnSwitch = val;
  }

  checkSvgObject(ev: any, item: any) {
    if (ev.checked) {
      let elem: any; elem = document.getElementById(ev.source.value);
      elem.style.opacity = "1";
      this.selectedCustomDimensions.push(item._id)
      console.log('this.selectedCustomDimensions', this.selectedCustomDimensions);

    } else {
      let elem: any;
      elem = document.getElementById(ev.source.value);
      elem.style.opacity = "0";
      this.selectedCustomDimensions.map((x: any, index: number) => {
        if (x === item._id) {
          this.selectedCustomDimensions.splice(index, 1)
        }
      })
    }
  }

  selectPrior() {
    const modalBox: any = document.getElementsByClassName('modalContainer')[0];
    modalBox.style.display = 'block';
  }

  okayPrior() {
    if (this.addFabrics.controls.fabricProvide.value === 'myself') {
      this.imageForCYOD = []
      this.vendorFabricSelection.map((x: any) => {
        this.imageForCYOD.push({
          fRimage: '',
          imagePreview: this.imageUrl + '/product/' + x.image,
          garmentTypeId: x.garmentTypeId,
          freezeFabric: x.freezeFabric,
          name: x.name,
          color: x.color,
          materialCategory: x.materialCategory,
          materialSubCategory: x.materialSubCategory,
          materialPattern: x.materialPattern,
          fabricLength: x.fabricLength,
          price: x.price,
          fabricWidth: x.fabricWidth,
          quantity: x.quantity,
          imageOpacity: x.imageOpacity
        });
      })
    }
    console.log('imageForCYOD', this.imageForCYOD);

    const modalBox: any = document.getElementsByClassName('modalContainer')[0];
    modalBox.style.display = 'none';
  }

  cancelPrior() {
    const modalBox: any = document.getElementsByClassName('modalContainer')[0];
    modalBox.style.display = 'none';
  }

  addSupplierFabric(ev: any, item: any, i: number) {
    if (ev.target.checked) {
      this.vendorFabricSelection.push(item)
    } else {
      this.vendorFabricSelection.splice(i, 1)
    }
  }

  selectSupplierFabric(ev: any, item: any, i: number) {
    if (ev.checked) {
      this.vendorFabricSelection.push(ev.source.value)
    } else {
      this.vendorFabricSelection.splice(i, 1)
    }
  }

  triggerClick() {
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
  }

  addSameValue(ev: any) {
    if (this.isEditing) {
      this.arrayFinalResult.map((x: any, index: number) => {
        if (index > 0) {
          if (ev === 'quantity') { x.quantity = this.arrayFinalResult[0].quantity; }
          if (ev === 'price') { x.price = this.arrayFinalResult[0].price; }
          if (ev === 'offerPrice') { x.offerPrice = this.arrayFinalResult[0].offerPrice; }
          if (ev === 'priceWithoutFabric') { x.priceWithoutFabric = this.arrayFinalResult[0].priceWithoutFabric; }
          if (ev === 'salePriceWithoutFabric') { x.salePriceWithoutFabric = this.arrayFinalResult[0].salePriceWithoutFabric; }
          if (ev === 'sku') { x.sku = this.arrayFinalResult[0].sku; }
          if (ev === 'image') {
            x.variation_image = this.arrayFinalResult[0].variation_image;
            x.variation_image_preview = this.arrayFinalResult[0].variation_image_preview;
          }
          // x.attributes = this.arrayFinalResult[0].attributes;          
        }
      })
    }
  }

  inventoryOptions(event: any) {
    if (event.value === 'product') {
      this.step3VisiblePart = true;
      this.productAttributes.controls.pricingOptions.setValue('product');
    } else {
      this.step3VisiblePart = false;
      this.productAttributes.controls.pricingOptions.setValue('variation');
      this.productPricing.controls.productPrice.clearValidators();
      this.productPricing.controls.productPrice.updateValueAndValidity();
    }
  }

  checkProductSubType(event: any) {
    console.log(event.value);

    if (event.value._id === '60a66ac66c3a8b132e9a3aba') {
      this.stepSixAndFiveFields = true;
      this.productAttributes.controls.pricingOptions.setValue('variation');
      this.productAttributes.controls.inventory.setValue('variation');
    } else {
      this.stepSixAndFiveFields = false;
      this.imageForCYOD = [];
    }

    if (event.value._id === '60a66abc6c3a8b132e9a3ab9') {
      this.stepFabricShowField = true;
    } else {
      this.stepFabricShowField = false;
    }
    const data = { product_type: event.value.parentId, product_sub_type: event.value._id };
    this.getProductCategory(data);
  }

  formsInit() {
    this.productCategory = this.fb.group({
      productType: ['', [Validators.required]],
      // productSubType: ['', [Validators.required]],
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
    });
    this.productInfo = this.fb.group({
      productName: ['', [Validators.required]],
      productDescription: ['', [Validators.required]],
    });
    this.productAttributes = this.fb.group({
      inventory: ['product'],
      totalQuantity: [''],
      pricingOptions: [''],
      sku: [''],
      primaryColor: [''],
      attributes: [''],
      fabricLengthUnit: [''],
      fabricWidthUnit: [''],
      fabricWidth: [''],
      scaleValue: ['']
    });
    this.filtersAttributes = this.fb.group({
      filters: [''],
    });
    this.productPricing = this.fb.group({
      productPrice: [''],
      priceWithoutFabric: [''],
      salePrice: [''],
      salePriceWithoutFabric: [''],
      selectPricing: [''],
      fabricUnit: [''],
    });
    this.imageForm = this.fb.group({
      image: ['', [Validators.required]]
    });
    this.addFabrics = this.fb.group({
      fabricProvide: ['', [Validators.required]],
      fabricLengthUnit: [''],
      fabricWidthUnit: [''],
    });
    this.customDimension = this.fb.group({
    });
    this.fabricOrientation = this.fb.group({});
    this.reviewForm = this.fb.group({
      productType: [{ value: '', disabled: true }],
      // productSubType: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      subCategory: [{ value: '', disabled: true }],
      fabricTypeCategory: [{ value: '', disabled: true }],
      fabricTypeSubCategory: [{ value: '', disabled: true }],
      inventory: [{ value: '', disabled: true }],
      attributes: [{ value: '', disabled: true }],
      fabricLengthUnit: [{ value: '', disabled: true }],
      fabricWidthUnit: [{ value: '', disabled: true }],
      fabricWidth: [{ value: '', disabled: true }],
      totalQuantity: [{ value: '', disabled: true }],
      pricingOptions: [{ value: '', disabled: true }],
      sku: [{ value: '', disabled: true }],
      primaryColor: [{ value: '', disabled: true }],
      productName: [{ value: '', disabled: true }],
      productDescription: [{ value: '', disabled: true }],
      productPrice: [{ value: '', disabled: true }],
      priceWithoutFabric: [{ value: '', disabled: true }],
      salePrice: [{ value: '', disabled: true }],
      salePriceWithoutFabric: [{ value: '', disabled: true }],
      selectPricing: [{ value: '', disabled: true }],
      fabricProvide: [{ value: '', disabled: true }],
      scaleValue: [{ value: '', disabled: true }],
    });
  }

  deleteItem(item: any, index: number) {
    Swal.fire({
      title: 'Are you sure that you want to delete this value?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Okay'
    }).then((result) => {
      if (result.isConfirmed) {
        const dummArr: any = [];
        const dummArrName: any = [];
        for (let i = 0; i < this.attributeValueData.length; i++) {
          for (let j = 0; j < this.attributeValueData[i].selectedAttr.length; j++) {
            if (this.attributeValueData[i].selectedAttr[j] === item._id) {
              this.attributeValueData[i].selectedAttr.splice(j, 1);
            }
          }
          for (let j = 0; j < this.attributeValueData[i].selectedAttrName.length; j++) {
            if (this.attributeValueData[i].selectedAttrName[j] === item.title) {
              this.attributeValueData[i].selectedAttrName.splice(j, 1);
            }
          }
        }
        this.attributeValueData.map((setArr: any) => {
          dummArr.push(setArr.selectedAttr);
          dummArrName.push(setArr.selectedAttrName);
        });

        const loopOver = (arr: any, str = '', final: any = []) => {
          if (arr.length > 1) {
            arr[0].forEach((v: any) => loopOver(arr.slice(1), str + v + ',', final));
          } else {
            arr[0].forEach((v: any) => final.push(str + v));
          }
          return final;
        };

        const charSet = dummArr;
        const arrResult = loopOver(charSet);
        const attrValue: any = [];
        arrResult.map((ar: any) => {
          this.attributeValueData.map((avd: any, i: number) => {
            if (ar.includes(this.checkSubAttributeType(ar, i))) {
              attrValue.push({ attributeId: avd.id, attributeValues: this.checkSubAttributeType(ar, i) });
            }
          });
        });

        this.finalCombArr = this.chunkArray(attrValue, this.attributeValueData.length);
        const charSetName = dummArrName;
        const arrResultName = loopOver(charSetName);
        this.arrayFinalResult = [];
        arrResultName.map((afr: any) => {
          this.arrayFinalResult.push({ variation_image: '', variation_image_preview: '', sku: '', quantity: '', price: '', offerPrice: '', priceWithoutFabric: '', salePriceWithoutFabric: '', combination: afr, attributes: [] });
        });


        this.apiService.get(`/vendorportal/product/attribute-value/${item._id}`).subscribe((res: any) => {
          this.attributeValueData.map((x: any) => {
            if (x.id === item.attributeId) {
              x.data.splice(index, 1);
            }
          });

          this.apiService.alert(res.message, 'success');
        }, err => { this.apiService.alert(err.message, 'error'); });
      }
    })
  }

  selectAttrVal(event: any, item: any) {
    const dummArr: any = [];
    const dummArrName: any = [];
    if (event.checked) {
      this.attributeValueData.map((cat: any) => {
        if (cat.id === item) {
          cat.selectedAttr.push(event.source.value);
          cat.selectedAttrName.push(event.source.id);
        }
      });
    } else {
      if (this.attributeValueData.length > 0) {
        for (let i = 0; i < this.attributeValueData.length; i++) {
          for (let j = 0; j < this.attributeValueData[i].selectedAttr.length; j++) {
            if (this.attributeValueData[i].selectedAttr[j] === event.source.value) {
              this.attributeValueData[i].selectedAttr.splice(j, 1);
            }
          }
          for (let j = 0; j < this.attributeValueData[i].selectedAttrName.length; j++) {
            if (this.attributeValueData[i].selectedAttrName[j] === event.source.id) {
              this.attributeValueData[i].selectedAttrName.splice(j, 1);
            }
          }
        }
      }
    }
    this.attributeValueData.map((setArr: any) => {
      dummArr.push(setArr.selectedAttr);
      dummArrName.push(setArr.selectedAttrName);
    });

    const loopOver = (arr: any, str = '', final: any = []) => {
      if (arr.length > 1) {
        arr[0].forEach((v: any) => loopOver(arr.slice(1), str + v + ',', final));
      } else {
        arr[0].forEach((v: any) => final.push(str + v));
      }
      return final;
    };
    const charSet = dummArr;
    const arrResult = loopOver(charSet);
    const attrValue: any = [];
    arrResult.map((ar: any) => {
      this.attributeValueData.map((avd: any, i: number) => {
        if (ar.includes(this.checkSubAttributeType(ar, i))) {
          attrValue.push({ attributeId: avd.id, attributeValues: this.checkSubAttributeType(ar, i) });
        }
      });
    });

    this.finalCombArr = this.chunkArray(attrValue, this.attributeValueData.length);
    const charSetName = dummArrName;
    const arrResultName = loopOver(charSetName);
    this.arrayFinalResult = [];
    arrResultName.map((afr: any) => {
      this.arrayFinalResult.push({ variation_image: '', variation_image_preview: '', sku: '', quantity: '', price: '', offerPrice: '', priceWithoutFabric: '', salePriceWithoutFabric: '', combination: afr, attributes: [] });
    });
    this.arrayFinalResult[0].variation_image = this.imageForm.value.image;
    this.arrayFinalResult[0].variation_image_preview = this.imageSrc;
  }

  selectFilterVal(event: any, item: any) {
    if (event.checked) {
      this.filterValueData.map((cat: any) => {
        if (cat.id === item) {
          cat.selectedAttr.push(event.source.value);
          cat.selectedAttrName.push(event.source.id.split('1').join(''));
        }
      });
    } else {
      if (this.filterValueData.length > 0) {
        for (let i = 0; i < this.filterValueData.length; i++) {
          for (let j = 0; j < this.filterValueData[i].selectedAttr.length; j++) {
            if (this.filterValueData[i].selectedAttr[j] === event.source.value) {
              this.filterValueData[i].selectedAttr.splice(j, 1);
            }
          }
          for (let j = 0; j < this.filterValueData[i].selectedAttrName.length; j++) {
            if (this.filterValueData[i].selectedAttrName[j] === event.source.id.split('1').join('')) {
              this.filterValueData[i].selectedAttrName.splice(j, 1);
            }
          }
        }
      }
    }
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type !== 'image/svg+xml') {
        if (event.target.files[0].type.split('/')[0] === 'image') {
          const URL = window.URL || window.webkitURL;
          const Img = new Image();
          const filesToUpload = (event.target.files);
          Img.src = URL.createObjectURL(filesToUpload[0]);
          Img.onload = (e: any) => {
            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.imageSrc = reader.result as string;
              this.imageChangedEvent = event;
            };
          };
        } else { this.apiService.alert('Invalid File Format', 'error'); }
      } else { this.apiService.alert('Invalid File Format', 'error'); }
    }
  }

  variantImage(event: any, i: number) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type !== 'image/svg+xml') {
        if (event.target.files[0].type.split('/')[0] === 'image') {
          const URL = window.URL || window.webkitURL;
          const Img = new Image();
          const filesToUpload = (event.target.files);
          Img.src = URL.createObjectURL(filesToUpload[0]);
          Img.onload = (e: any) => {
            const height = e.path[0].height;
            const width = e.path[0].width;

            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.arrayFinalResult[i].variation_image_preview = reader.result as string;
              this.arrayFinalResult[i].variation_image = event.target.files[0];
              this.addSameValue('image')
            };

          };
        } else { this.apiService.alert('Invalid File Format', 'error'); }
      } else { this.apiService.alert('Invalid File Format', 'error'); }
    }
  }

  fabricImage(event: any, i: number) {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imageCompress.compressFile(image, orientation).then(
        result => {
          this.imgResultAfterCompress = result;
          this.imageForCYOD[i].imagePreview = this.imgResultAfterCompress;
          this.imageForCYOD[i].fRimage = this.dataURLtoFile(result, 'image.jpeg');
        }
      );

    });
    console.log( 'IMAGE',this.imageForCYOD);
  }

  handleFileInput(event: any) {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].type !== 'image/svg+xml') {
          const URL = window.URL || window.webkitURL;
          const Img = new Image();

          const filesToUpload = (event.target.files);
          Img.src = URL.createObjectURL(filesToUpload[i]);

          Img.onload = (e: any) => {

            // const height = e.path[0].height;
            // const width = e.path[0].width;

            const reader = new FileReader();
            reader.onload = (ev: any) => {
              if (ev.target.result.split('/')[0].split(':')[1] === 'image') {
                this.files.push({
                  preview: ev.target.result,
                  file: event.target.files[i]
                });

              } else {
                this.apiService.alert('Invalid File Format', 'error');
              }
            };
            reader.readAsDataURL(event.target.files[i]);

          };

        } else {
          this.apiService.alert('Invalid File Format', 'error');
        }

      }
    }

    
  }

  getGarmentTypes() {
    this.apiService.get(`/vendorportal/masterdata/garment-types`).subscribe((res: any) => {
      if (res) {
        this.garmentTypes = res.data;
      }
    });
  }

  checkOrientation(val: any) {
    this.isOrientation = val;
  }

  submit(formValue: any, formName: string) {
    if (formName === 'productCategory') {
      if (this.productCategory.valid) {
        this.reviewForm.patchValue(formValue);
      } else {
        this.productCategory.markAllAsTouched();
      }
    }
    if (formName === 'productInfo') {
      if (this.productInfo.valid) {

        this.reviewForm.patchValue(formValue);
      } else {
        this.productInfo.markAllAsTouched();
      }
    }
    if (formName === 'productAttributes') {
      this.arrayFinalResult.map((fa: any, i: number) => {
        this.finalCombArr.map((fc: any, j: number) => {
          if (i === j) {
            fa.attributes = fc;
          }
        });
      });
      let newCreatedCOmbArr: any = [];
      newCreatedCOmbArr = this.arrayFinalResult;
      const variationImage: any = [];

      newCreatedCOmbArr.map((x: any) => { variationImage.push(x.variation_image); });
      this.variationImages = variationImage;
      this.finalAttributeCombData = _.map(newCreatedCOmbArr, function (o) { return _.omit(o, 'combination', 'variation_image_preview', 'variation_image'); });

      if (this.productAttributes.valid) {
        this.reviewForm.patchValue(formValue);
      } else {
        this.productAttributes.markAllAsTouched();
      }
    }
    if (formName === 'filtersAttributes') {
      let filterAttrVal: any = [];
      this.filterValueData.map((x: any) => {
        filterAttrVal.push({ attributeId: x.id, attributeValues: x.selectedAttr })
      })
      this.finalFilterValueData = filterAttrVal;
    }
    if (formName === 'productPricing') {
      if (this.productPricing.valid) {

        this.reviewForm.patchValue(formValue);
      } else {
        this.productPricing.markAllAsTouched();
      }
    }
    if (formName === 'imageForm') {
      if (this.imageForm.valid) {
      } else {
        this.imageForm.markAllAsTouched();
      }
    }
    if (formName === 'addFabrics') {
      if (this.addFabrics.valid) {
        this.reviewForm.patchValue(formValue);
        let imageForCYODArr: any = [];
        this.imageForCYOD.map((x: any, i: number) => {
          x.garmentTypeId.map((y: any, index: number) => {
            imageForCYODArr.push({
              fRimage: x.fRimage, imagePreview: x.imagePreview, garmentTypeId: y, freezeFabric: x.freezeFabric,
              name: x.name, color: x.color, materialCategory: x.materialCategory, materialSubCategory: x.materialSubCategory, materialPattern: x.materialPattern,
              fabricLength: x.fabricLength, price: x.price, fabricWidth: x.fabricWidth, quantity: x.quantity, imageOpacity: x.imageOpacity
            })
          })
        });
        let newCreatedCOmbArr: any = [];
        console.log(imageForCYODArr);

        newCreatedCOmbArr = imageForCYODArr;

        const fabricImage: any = [];
        newCreatedCOmbArr.map((x: any, index: number) => {
          fabricImage.push(x.fRimage);
          if (x.fRimage) { x.image = '' } else { x.image = x.imagePreview.split(this.imageUrl + '/product/').join('') }
        });

        this.cyodImages = fabricImage;
        this.imageForCYODFinal = _.map(newCreatedCOmbArr, function (o) { return _.omit(o, 'imagePreview', 'fRimage'); });

        this.reviewForm.controls.fabricTypeCategory.setValue(this.fabricTypeCategory);
        this.reviewForm.controls.fabricTypeSubCategory.setValue(this.fabricTypeSubCategory);
        this.allCyodData = newCreatedCOmbArr;
        // console.log('allCyodData', this.allCyodData);
      } else {
        this.addFabrics.markAllAsTouched();
      }
    }
    if (formName === 'customDimension') {
      if (this.customDimension.valid) {
        let png: any;
        let modelImg: any = document.getElementById('modelImg');
        let svgString = new XMLSerializer().serializeToString(modelImg);
        let canvas: any = document.getElementById("canvas");
        let ctx = canvas.getContext("2d");
        let DOMURL = self.URL || self.webkitURL || self;
        let img = new Image();
        let svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        let url = DOMURL.createObjectURL(svg);
        img.onload = function () {
          ctx.drawImage(img, 0, 0);
          png = canvas.toDataURL("image/png");
          const pngContainer: any = document.getElementById('png-container');
          pngContainer.innerHTML = '<img src="' + png + '"/>';
          DOMURL.revokeObjectURL(png);
        };
        img.src = url;
        setTimeout(() => {
          const file = this.dataURLtoFile(png, 'image.png');
          this.customDimensionImage = file;

          const toBase64 = (file: any) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

          async function Main() {
            await toBase64(file);
          }

          Main();
        }, 1000);

      } else {
        this.customDimension.markAllAsTouched();
      }
    }
    this.editingStatus = true;
  }

  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  saveFabric() {
    this.updatedPattern = localStorage.getItem('pattern');
  }

  finalSubmit() {
    this.totalprice = (this.fabricPriceValue + this.selectedPlus + this.selectedCYOD + this.selectedLongDress);
    const formData = new FormData();
    if (this.reviewForm.value.productType) {
      formData.append(`product_type`, this.reviewForm.value.productType?.parentId);
    }
    if (this.reviewForm.value.productType) {
      formData.append(`product_sub_type`, this.reviewForm.value.productType?._id);
    }
    if (this.reviewForm.value.category) {
      formData.append(`category`, this.reviewForm.value.category);
    }
    if (this.reviewForm.value.subCategory) {
      formData.append(`subCategory`, this.reviewForm.value.subCategory);
    }
    if (this.reviewForm.value.fabricTypeCategory) {
      formData.append(`fabricTypeCategory`, this.reviewForm.value.fabricTypeCategory);
    }
    if (this.reviewForm.value.fabricTypeSubCategory) {
      formData.append(`fabricTypeSubCategory`, this.reviewForm.value.fabricTypeSubCategory);
    }
    if (this.reviewForm.value.inventory) {
      formData.append(`inventoryBased`, this.reviewForm.value.inventory);
    }
    if (this.reviewForm.value.pricingOptions) {
      formData.append(`priceBased`, this.reviewForm.value.pricingOptions);
    }
    if (this.reviewForm.value.totalQuantity) {
      formData.append(`quantity`, this.reviewForm.value.totalQuantity);
    }
    if (this.reviewForm.value.primaryColor) {
      formData.append(`primaryColor`, this.reviewForm.value.primaryColor);
    }
    if (this.reviewForm.value.sku) {
      formData.append(`sku`, this.reviewForm.value.sku);
    }
    if (this.reviewForm.value.productName) {
      formData.append(`title`, this.reviewForm.value.productName);
    }
    if (this.reviewForm.value.productDescription) {
      formData.append(`description`, this.reviewForm.value.productDescription);
    }
    if(this.totalprice){
      formData.append(`totalPrice`, this.totalprice);
    }
    if (this.reviewForm.value.productPrice) {
      formData.append(`price`, this.reviewForm.value.productPrice);
    }
    if (this.value) {
      formData.append('scaleValue', this.value.toString());
    }
    if (this.productPricing.value.fabricUnit) {
      formData.append('fabricUnit', this.productPricing.value.fabricUnit);
    }
    if (this.reviewForm.value.priceWithoutFabric) {
      formData.append(`priceWithoutMaterial`, this.reviewForm.value.priceWithoutFabric);
    }
    if (this.reviewForm.value.salePriceWithoutFabric) {
      formData.append(`offerPriceWithoutMaterial`, this.reviewForm.value.salePriceWithoutFabric);
    }
    if (this.reviewForm.value.salePrice) {
      formData.append(`offerPrice`, this.reviewForm.value.salePrice);
    }
    if (this.imageForm.value.image) {
      formData.append('image', this.imageForm.value.image);
    }
    if (this.files.length && this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file) {
          formData.append(`imageGallery_${i}`, this.files[i].file);
        }
      }
    }
    if(this.gender == 'male'){
      formData.append(`gender`, this.gender);
      if (this.mirrorArrSeclect > 0) {
        for (let i = 0; i < this.mirrorArrSeclect.length; i++) {
          formData.append(`mirrorsize_male[${i}]`, this.mirrorArrSeclect[i]);
        }
      }
    }else{
      formData.append(`gender`, this.gender);
      if (this.mirrorArrSeclect > 0) {
        for (let i = 0; i < this.mirrorArrSeclect.length; i++) {
          formData.append(`mirrorsize_female[${i}]`, this.mirrorArrSeclect[i]);
        }
      }
    }
    if (this.finalAttributeCombData) {
      formData.append(`attributes`, JSON.stringify(this.finalAttributeCombData));
    }
    if (this.finalFilterValueData) {
      formData.append(`filterAttributes`, JSON.stringify(this.finalFilterValueData));
    }
    if (this.reviewForm.value.fabricLengthUnit) {
      formData.append(`fabricLengthUnit`, this.reviewForm.value.fabricLengthUnit);
    }
    if (this.reviewForm.value.fabricWidthUnit) {
      formData.append(`fabricWidthUnit`, this.reviewForm.value.fabricWidthUnit);
    }
    if (this.reviewForm.value.fabricWidth) {
      formData.append(`fabricWidth`, this.reviewForm.value.fabricWidth);
    }
    if (this.variationImages && this.variationImages.length && this.variationImages.length > 0) {
      for (let i = 0; i < this.variationImages.length; i++) {
        if (this.variationImages[i]) {
          formData.append(`variationimage_${i}`, this.variationImages[i]);
        }
      }
    }
    if (this.reviewForm.value.fabricProvide) {
      formData.append(`fabricProvider`, this.reviewForm.value.fabricProvide);
    }
    if (this.imageForCYODFinal) {
      formData.append(`garmentTypes`, JSON.stringify(this.imageForCYODFinal));
    }
    if (this.cyodImages && this.cyodImages.length && this.cyodImages.length > 0) {
      for (let i = 0; i < this.cyodImages.length; i++) {
        if (this.cyodImages[i]) {
          formData.append(`cyodimage_${i}`, this.cyodImages[i]);
        }
      }
    }
    if (this.plusSizePrice) {
      formData.append(`plusSizePrice`, JSON.stringify(this.plusSizePrice));
    }
    if (this.cyodSizePrice) {
      formData.append(`ownDesignPrice`, JSON.stringify(this.cyodSizePrice));
    }
    if (this.longDressPrice) {
      formData.append(`longDressPrice`, JSON.stringify(this.longDressPrice));
    }
    if (this.priceCalculateOn) {
      formData.append(`priceCalculateOn`, this.priceCalculateOn);
    }
    if (this.fabricPriceValue) {
      formData.append(`choosePrice`, JSON.stringify(this.fabricPriceValue));
    }
    if (this.selectedCustomDimensions && this.selectedCustomDimensions.length > 0) {
      formData.append(`customDimension`, JSON.stringify(this.selectedCustomDimensions));
    }
    if (this.customDimensionsComment && this.customDimensionsComment.length > 0) {
      formData.append(`customDimensionComment`, JSON.stringify(this.customDimensionsComment));
    }
    if (this.customDimensionImage) {
      formData.append(`customDimensionImage`, this.customDimensionImage);
    }
    if (this.vendorFabricSelection && this.vendorFabricSelection.length > 0) {
      formData.append(`shopdropSupplier`, JSON.stringify(this.vendorFabricSelection));
    }
    formData.append(`fabricOrientation`, this.isOrientation);
    formData.append(`productUnit`, this.lengthType);
    this.apiService.postMultiData(`/vendorportal/product/add`, formData).subscribe((res: any) => {
      if (res.status === 200) {
        this.apiService.alert(res.message, 'success');
        this.reviewForm.reset();
        this.productCategory.reset();
        this.productInfo.reset();
        this.productPricing.reset();
        this.editingStatus = false;
        this.isDirty = false;
        this.router.navigateByUrl('/vendor/product-listing');
      }
    });
  }

  switchLength(val: string) {
    this.lengthType = val;
    sessionStorage.setItem("dimensionLengthType", JSON.stringify(this.lengthType));
  }
  switchGender(val: string) {
    this.gender = val;
    this.getMirrorsize(this.gender);
    sessionStorage.setItem("dimensionGenderType", JSON.stringify(this.gender));
  }
  imagePreview(data: any) {
    setTimeout(() => {
      this.mirror_image = data?.image;
    },100);
  }
  
  checkPriceFilter(ev?: any ) {
    console.log(ev);
    
    if (ev.checked) {
      const index = this.mirrorArrSeclect?.findIndex((data:any) => {
        if (data == ev.source.value) { return data; }
      })
      if (index == -1) {
        this.mirrorArrSeclect.push(ev.source.value);
      }
    } else {
      if (this.mirrorArrSeclect.indexOf(ev.source.value) > -1) {
        this.mirrorArrSeclect.splice(this.mirrorArrSeclect.indexOf(ev.source.value), 1);
      }
    }
    
    console.log(this.mirrorArrSeclect);
    
  }
  onCheck(id: any) {
    // console.log(this.mirrorArrSeclect);
    
    const index = this.mirrorArrSeclect.findIndex((data: any) => {
      if (data === id) {
        return data;
      }
    });
    if (index > -1) { return true; } else { return false; }
  }

  editHelp(item: any) {
    const dialogRef = this.dialog.open(EditHelpComponent, {
      width: '400px',
      data: item,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customDimensionsComment = [];
        this.customDimensions.map((x: any) => {
          if (x._id === result._id) {
            this.customDimensionsComment.push(result.comment);
          } else {
            this.customDimensionsComment.push(x.comment);
          }
        })
      }
    });
  }

  onBlurMethod(index: number) {
    let imageCheckCYOD: any = [] = _.uniq(this.imageForCYOD, 'name');
    if (this.imageForCYOD.length !== imageCheckCYOD.length) {
      this.apiService.alert('Name field need to be unique', 'error');
      this.imageForCYOD[index].name = '';
    }
  }

  checkFabricLength(event: any) {
    this.addFabrics.controls.fabricLengthUnit.patchValue(event.value);
    this.addFabrics.controls.fabricWidthUnit.patchValue(event.value);
  }

  ngOnDestroy() {
    if (this.editingStatus) {
    }
  }

}
