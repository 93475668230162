import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  productId: any;
  detailsData: any;
  baseImgUrl: string;
  countries: any;
  dimensionValue:any;
  baseImgUrls: string;
  more:boolean = false;
  sizeType:any='inch';
  constructor(
    private ar: ActivatedRoute,
    private api: ApiService
  ) { 
    this.baseImgUrl = environment.IMAGE_BASE_URL;
    this.baseImgUrls = environment.BASE_API_URL;

  }

  ngOnInit(): void {
    this.getCountries();
    this.ar.params.subscribe((x:any) => {
      this.productId = x.id;
      this.api.post(`/vendorportal/order/detail`, { orderId: this.productId }).subscribe((resp: any) => {
        this.detailsData = resp.data;  
        console.log(resp);
              
      })
    })
  }

  getCountries() {
    this.api.get(`/vendorportal/masterdata/countries`).subscribe((res: any) => {
      this.countries = res.data;
    });
  }

  getCountryNameById(countryId: any) {
    if (this.countries && countryId) {
      const countryName = this.countries.find((el: any) => el._id === countryId);
      return countryName.name;
    }
  }

  checkPrice(val:any):any {
    let updateValue:number;
    if(this.detailsData.orderedItems[0].productDetails.productUnit === 'cm' && this.detailsData?.dimensionLengthType === 'inch') {
      updateValue = val/2.54;
      console.log('inch');
      this.dimensionValue = 'inch';
      return Math.round(updateValue);
    }
    if(this.detailsData.orderedItems[0].productDetails.productUnit === 'inch' && this.detailsData?.dimensionLengthType === 'cm') {
      updateValue = val*2.54;
      console.log('cm');      
      this.dimensionValue = 'cm';
      return Math.round(updateValue);
    }
    if(this.detailsData.orderedItems[0].productDetails.productUnit === 'inch' && this.detailsData?.dimensionLengthType === 'inch') {
      updateValue = val;
      console.log('normal inch'); 
      this.dimensionValue = 'inch';
      return Math.round(updateValue);
    }
    if(this.detailsData.orderedItems[0].productDetails.productUnit === 'cm' && this.detailsData?.dimensionLengthType === 'cm') {
      updateValue = val;
      console.log('normal cm'); 
      this.dimensionValue = 'cm';
      return Math.round(updateValue);
    }
  }

  download(fileUrl: any, name: any) {
    saveAs(this.baseImgUrls + '/uploads/csv/' + fileUrl, name)
  }

  onChange(){
    this.more = !this.more;
  }
  switchLength(type:any){
    this.sizeType = type
  }

  abc=[
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm',image:'fcgvhb'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm',image:'fcgvhb'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},
    {key:'excvgbj',value_in:'2 in', value_cm:'3 cm'},

  ] 


}
