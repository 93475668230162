import { Component, OnInit } from '@angular/core';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  contactData: any;
  csvName: any;

  constructor(
    public api: ApiService
  ) { }

  ngOnInit(): void {
    this.getContacts();
  }

  getContacts() {
     this.api.get(`/vendorportal/sendinblue/contact-list`).subscribe(res => {
            this.contactData = res.data;
     }, err => {
      this.api.alert('Something went wrong', 'error');
     })
  }

  deleteContact(id: any) {
    this.api.alertModal('Are you sure ?', 'You will not be able to revert this!', 'warning' , true, true).then((result: any) => {
      if(result.isConfirmed) {
        this.api.get(`/vendorportal/sendinblue/contact-delete/${id}`).subscribe(res => {
          this.getContacts();
          this.api.alert(res.message, 'success')
        }, err => {
          this.api.alert(err.message, 'error')
        })
      }
    })
  }

  uploadCSV(csvFiles: any) {
    const file: File = csvFiles.files[0];
    if (file.type.match(/csv\/*/) === null) {
      this.api.alert('Only CSV are supported.', 'error');
      return;
    } else {
      const formData: any = new FormData();
      formData.append('csv_file', file);
      this.api.postMultiData(`/vendorportal/sendinblue/contact-import`, formData).subscribe(res => {
          this.api.alert(res.message, 'success');
          this.getContacts();  
      }, err => { this.api.alert('Invalid CSV format', 'error');})
    }
  }
}