<section class="hero">
    <div class="container">
        <div class="row">
            <div class="verify-content" *ngIf="!varifyFlag">
                <p>
                   Your email is verifing...
                </p>
            </div>
            <div class="verify-content" *ngIf="varifyFlag">
                <h1>Email Verified</h1>
                <p>
                    <mat-icon>check_circle</mat-icon> Thank you for veryfying your email and keeping your Shopdrop account.
                </p>
                <button routerLink="/vendor-home" >Sign in Now</button>
            </div>
            <div class="col-12">
                
            </div>

            
        </div>
    </div>
</section>
